import { string } from 'prop-types';

import { AccountFaceOutlineSVG } from 'components/iconsSVG/accountFaceOutline';
import { SadFaceSVG } from 'components/iconsSVG/sadFace';
import { useI18n } from 'context/i18n';
import { IS_CAMPER } from 'utils/constants/system';

import { getLang } from 'utils/helpers';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { TWS_MAGAZINES, TWS_MAGAZINE_LANGS } from 'utils/constants/tws';
import styles from './style.module.css';

export const ThankYouMessage = ({ type, className, href }) => {
  const { t, profileData, locale } = useI18n();
  const lang = getLang(locale);
  const { emptyBagCollections } = profileData;

  const config = {
    notifyme: {
      icon: 'face',
      title: () => t('generico', 'thank.you.notifyme'),
      subtitle: () => t('generico', 'see.you.soon.notifyme'),
      cta: () => '',
      showCollections: false,
    },
    appointment: {
      icon: 'face',
      title: () => t('generico', 'thank.you'),
      subtitle: () => t('generico', 'see.you.soon'),
      cta: () => '',
      showCollections: false,
    },
    ewallet: {
      icon: 'face',
      title: () => t('generico', 'thank.you.ewallet'),
      subtitle: () => t('generico', 'see.you.soon.ewallet'),
      cta: '',
      showCollections: false,
    },
    newsletter: {
      icon: 'socks',
      title: () => t('generico', 'thank.you.newsletter'),
      subtitle: () => t('generico', 'see.you.soon.newsletter'),
      cta: () => t('generico', 'thank.you.cta'),
      showCollections: true,
    },
    eventSuscribedUserNew: {
      icon: IS_CAMPER ? 'face' : 'nnormal',
      title: () => t('generico', 'thank.you.event.suscribe.user.new'),
      subtitleHtml: () => (
        <div className={styles.modalSubtitle} dangerouslySetInnerHTML={{ __html: t('generico', 'see.you.soon.event.suscribe.user.new') }}></div>
      ),
      cta: () => '',
      showCollections: false,
    },
    eventSuscribedUserExists: {
      icon: IS_CAMPER ? 'face' : 'nnormal',
      title: () => t('generico', 'thank.you.event.suscribe.user.exists'),
      subtitle: () => t('generico', 'see.you.soon.event.suscribe.user.exists'),
      cta: () => '',
      showCollections: false,
    },
    eventSuscribedAlready: {
      icon: IS_CAMPER ? 'face' : 'nnormal',
      title: () => t('generico', 'thank.you.event.suscribe.already'),
      subtitle: () => t('generico', 'see.you.soon.event.suscribe.already'),
      cta: () => '',
      showCollections: false,
    },
    eventSuscribedFull: {
      icon: IS_CAMPER ? 'sad' : 'nnormal',
      title: () => t('generico', 'thank.you.event.suscribe.full'),
      subtitle: () => t('generico', 'see.you.soon.event.suscribe.full'),
      cta: () => '',
      showCollections: false,
    },
    eventUnsuscribed: {
      icon: IS_CAMPER ? 'sad' : 'nnormal',
      title: () => t('generico', 'thank.you.event.unsuscribe.title'),
      subtitle: () => t('generico', 'see.you.soon.event.unsuscribe.subtitle'),
      cta: () => '',
      showCollections: false,
    },
    content: {
      icon: IS_CAMPER ? 'face' : 'nnormal',
      title: () => t('ficha.producto', 'modal.members.only.title.content'),
      subtitle: () => t('ficha.producto', 'modal.members.only.subtitle.content'),
      cta: () => t('ficha.producto', 'modal.members.only.cta.redirect'),
      ctaHref: href,
      showCollections: false,
    },
    reserve: {
      icon: IS_CAMPER ? 'face' : 'nnormal',
      title: () => t('generico', 'thank.you'),
      subtitleHtml: () => <p dangerouslySetInnerHTML={{ __html: t('generico', 'see.you.soon') }} />,
      showCollections: false,
    },
  };
  if (type.includes('magazine')) {
    const baseMagazine = {
      icon: 'face',
      title: () => t('generico', 'thank.you.magazine'),
      cta: () => t('generico', 'read.it.now', 'Read it now!'),
      showCollections: false,
    };
    const magazineType = type.split('-').pop();
    const ctaHref = TWS_MAGAZINES[magazineType in TWS_MAGAZINES ? magazineType : 'menorca'].replace('LANG', TWS_MAGAZINE_LANGS.includes(lang) ? lang : 'en');
    baseMagazine.ctaHref = ctaHref;
    config[type] = baseMagazine;
  }

  const icons = {
    face: <AccountFaceOutlineSVG width={50} height={50} />,
    socks: <img src="/assets-new/newsletter-subscription-socks.gif" alt="Subscription OK!" width={90} height={90} />,
    sad: <SadFaceSVG width={50} height={50} />,
    nnormal: <img src={'/assets-new/logo-nnormal.svg'} alt={'Nnormal Logo'} width={200} height={80} />,
  };

  const handleClickCollectionButton = (collectionName) => {
    dataLayerHandleEvent({
      event: 'select_enrichment_end_promotion',
      event_category: 'acquisition',
      event_detail_1: collectionName,
    });
  };

  if (!(type in config)) {
    console.error('Unrecognized type in thank you message.');
    return null;
  }

  return (
    <div className={`${styles.thankYouWrapper} ${className}`}>
      {icons[config[type].icon]}
      <p className={styles.modalTitle}>{config[type].title()}</p>
      {config[type].subtitleHtml && config[type].subtitleHtml()}
      {config[type].subtitle && <p className={styles.modalSubtitle}>{config[type].subtitle()}</p>}
      {config[type]?.ctaHref && (
        <a href={config[type].ctaHref} className={styles.ctaHref} target="_blank" rel="noreferrer">
          {config[type].cta()}
        </a>
      )}
      {IS_CAMPER && config[type].showCollections && (
        <>
          <p className={styles.modalSubtitle}>{config[type].cta()}</p>
          <div className={styles.buttonContainer}>
            {emptyBagCollections?.map((item) => (
              <a
                key={`collectionButton-${item.title}`}
                href={item.url}
                onClick={() => handleClickCollectionButton(item.title)}
                className={styles.collectionButton}
              >
                {item.title}
              </a>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

ThankYouMessage.propTypes = {
  type: string,
  className: string,
  href: string,
};
