/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect, useId } from 'react';
import { useRouter } from 'next/router';

import { Modal, Button, Form, Spin, Input, Select, Radio, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { SHA256 } from 'crypto-js';

import FormInput from 'components/formInput';
import { CloseMdSVG, CloseModalSVG } from 'components/iconsSVG/close';
import { ChevronSVG } from 'components/iconsSVG/chevron';
import { useI18n } from 'context/i18n';
import { useUser } from 'context/user';
import { PhoneInput } from 'components/base';
import PrivacyPolicy from 'components/privacyPolicy';

import { getMarket, getLang, getCookie, COOKIES, CLOSE_SELLIGENT_POPUP } from 'utils/helpers';
import { getConfigByLocale } from 'utils/getConfigByLocale';
import { subscribeNewsletter, updateNewsletter, getIsSelligent } from 'actions/general';

import { dataLayerHandleEvent } from 'utils/dataLayers';
import { IS_CAMPER, IS_NNORMAL } from 'utils/constants/system';
import { useLink, useValidate } from 'hooks';
import { useEventListener } from 'hooks/useEventListener';
import { isBlank } from 'utils/StringUtils';
import styles from './style.module.css';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { CopySVG } from 'components/iconsSVG/copy';
import { CheckSVG } from 'components/iconsSVG/check';

type TBehaviour = 'always_on' | 'private_promo' | 'private_enrichment';

const STEP = {
  SUBSCRIBE: 1,
  UPDATE: 2,
  THANKS: 3,
};

const defaultPictureURL = IS_NNORMAL ? '/assets-new/img/newsletter/nnormal-newsletter-default' : 'https://www.camper.com/img/BLF';
const ACQUISITION_CATEGORY = 'acquisition';
const acquisitionDetail = {
  [STEP.SUBSCRIBE]: 'acquisition_form',
  [STEP.UPDATE]: 'enrichment_form',
  [STEP.THANKS]: 'enrichment_end',
};

type GetLangsFromCountryArgs = {
  countryId: string;
  countries?: any[];
};

function getLangsFromCountry({ countryId, countries = [] }: GetLangsFromCountryArgs) {
  const foundCountry = countries.find((item) => item.id === countryId);

  if (foundCountry === undefined) {
    return [];
  }

  return foundCountry.languages;
}

type PictureProps = {
  newsletterImages: any[];
};

function Picture({ newsletterImages }: PictureProps) {
  const date = Date.now();

  const id = useId();
  let imgSrc = `${defaultPictureURL}.jpg?d=${date}`;
  let sources = [
    {
      type: 'image/avif',
      srcSet: `${defaultPictureURL}.avif?d=${date}`,
    },
    {
      type: 'image/webp',
      srcSet: `${defaultPictureURL}.webp?d=${date}`,
    },
  ];

  if (Array.isArray(newsletterImages) && newsletterImages.length > 0) {
    const jpgImage = newsletterImages.find((picture) => picture.fileName.includes('jpg')) ?? newsletterImages[0];
    imgSrc = `${jpgImage.url}${jpgImage.fileName}?d=${date}`;
    sources = newsletterImages.map((picture) => ({
      type: picture.tags[0],
      srcSet: `${picture.url}${picture.fileName}?d=${date}`,
    }));
  }

  return (
    <picture className="h-full w-full">
      {sources.map((source, index) => (
        <source key={`${id}-nw-img-source-${index}`} type={source.type} srcSet={source.srcSet} />
      ))}
      <img src={imgSrc} alt="Image of newsletter photo" className="h-full w-full object-cover" />
    </picture>
  );
}

function Icon() {
  if (IS_NNORMAL) {
    return <img src="/assets-new/logo-nnormal.svg" alt="" />;
  }
  return null;
}

type SubscribeStepProps = {
  alreadySub: boolean;
  onFinish: (values: any) => void;
  handleFormError: (fieldsData: any) => void;
  handleClickSubmit: () => void;
  popupData: any;
  cmsProps: any;
};

function SubscribeStep({ alreadySub, onFinish, handleFormError, handleClickSubmit, popupData, cmsProps }: SubscribeStepProps) {
  const { t, locale, popupInfo } = useI18n();
  const { userData } = useUser();
  // Derived from global state
  const isAlreadySubscribed = userData?.account?.newsletterSubscribed === true;
  const market = getMarket(locale);

  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const [emailForm] = Form.useForm();
  const [isValidEmail, setIsValidEmail] = useState<undefined | boolean>(undefined);

  let subtitle = popupData?.subtitle?.text ?? cmsProps?.newsletterContent?.description ?? popupInfo?.text;

  if (!subtitle) {
    subtitle =
      alreadySub ?
        t('mi.cuenta', 'login.titulo.bienvenido', 'Good to see you again')
      : t(
          'header.messages',
          `header.ribbon.01.${market}.text`,
          'Subscribe to our newsletter to receive an extra 10% off any product from our collection – just another perk of being part of the Camper family.',
        );
  }

  const handleEmailFocus = () => {
    if (hasBeenFocused === false) {
      dataLayerHandleEvent({
        event: 'start_acquisition_form',
        event_category: ACQUISITION_CATEGORY,
      });
      setHasBeenFocused(true);
    }
  };

  const handleEmailChange = async () => {
    try {
      // esta promesa hace un throw cuando falla la validacion del email asi que asumimos que si no falla es correcto, si falla es incorrecto y podemos setear el shouldShowPrivacy
      await emailForm.validateFields(['email']);
      // sin error
      if (!isValidEmail) {
        setIsValidEmail(true);
      }
    } catch (error) {
      if (isValidEmail) {
        setIsValidEmail(false);
      }
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="h-full w-full overflow-hidden max-md:hidden">
        <Picture newsletterImages={popupData?.image ? [popupData.image] : cmsProps?.newsletterPopupImages?.newsletterImages} />
      </div>
      <div className="flex min-h-[500px] flex-col items-center justify-center p-10 md:min-h-[636px] md:px-5">
        <div className="flex justify-center">
          <Icon />
        </div>
        <p
          className={`mb-4 text-center font-moderne-fat text-2xl nnormal:font-secondary md:text-3xl ${popupData?.title?.className || ''}`}
          style={popupData?.title?.style}
          dangerouslySetInnerHTML={{
            __html:
              popupData?.title?.text ??
              cmsProps?.newsletterPopup?.newsletterPopupClaim ??
              popupInfo?.title ??
              t('header.messages', `header.ribbon.01.${market}.title`, 'Camper Newsletter'),
          }}
        />
        <p
          className={`max-w-[275px] text-center text-sm empty:hidden md:max-w-[438px] md:text-base ${popupData?.subtitle?.className || ''}`}
          style={popupData?.subtitle?.style}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        <Form
          form={emailForm}
          layout="vertical"
          name="emailForm"
          onFinish={onFinish}
          requiredMark="optional"
          scrollToFirstError
          className={styles.emailForm}
          onFieldsChange={handleFormError}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('validation', 'required.email', 'Please input your email'),
              },
              {
                type: 'string',
                pattern: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
                message: '',
              },
            ]}
            required
          >
            <Input
              onFocus={handleEmailFocus}
              placeholder={t('generico', 'input.email.placeholder', 'Enter your email')}
              type="email"
              autoComplete="false"
              onChange={handleEmailChange}
            />
          </Form.Item>

          {isValidEmail === true || isAlreadySubscribed ?
            <>
              <PrivacyPolicy
                origin="newsletter"
                hideConditions
                checkBoxClass={market !== 'TR' ? styles.privacyCheckbox : `${styles.privacyCheckbox} ${styles.withMargin}`}
              />
              {alreadySub ?
                <span className="error">{t('newsletter', 'gracias.existe.mail')}</span>
              : null}
              <Form.Item className={styles.submitItem}>
                <Button
                  type="default"
                  htmlType="submit"
                  style={popupData?.submit?.style}
                  onClick={handleClickSubmit}
                  className={`${styles.submitBtn} ${popupData?.submit?.className || ''}`}
                >
                  <span dangerouslySetInnerHTML={{ __html: popupData?.submit?.text ?? t('generico', 'subscribe', 'Subscribe') }}></span>
                </Button>
              </Form.Item>
            </>
          : null}
        </Form>
      </div>
    </div>
  );
}

type UpdateStepProps = {
  onFinish: (values: any) => void;
  handleFormError: (fieldsData: any) => void;
  initialValues: any;
  handleClickSubmit: () => void;
  onSkip: () => void;
  popupData: any;
};

function UpdateStep({ onFinish, handleFormError, initialValues, handleClickSubmit, onSkip, popupData }: UpdateStepProps) {
  const {
    t,
    locale,
    countries,
    localeConfigs,
    profileData: { phonePrefixes },
  } = useI18n();
  const validate = useValidate();
  const initialCountry = getMarket(locale);
  const initialLanguage = getLang(locale);
  const localeFormat = getConfigByLocale(locale, localeConfigs);

  const [AdditionalForm] = Form.useForm();
  const [dateValue, setDateValue] = useState<Moment | undefined>(undefined);
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [selectedCountry, setSelectedCountry] = useState(initialCountry);
  const [selectedLang, setSelectedLang] = useState(initialLanguage);

  const countryRef = useRef();
  const langRef = useRef();

  const [hasBeenFocused, setHasBeenFocused] = useState(false);

  const handleUpdateFocus = () => {
    if (hasBeenFocused === false) {
      dataLayerHandleEvent({
        event: 'start_enrichment_form',
        event_category: ACQUISITION_CATEGORY,
      });
      setHasBeenFocused(true);
    }
  };

  const handleSearchCountry = (value) => {
    const result = countries?.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

    setFilteredCountries(result);
  };

  const handleChangeCountry = (country) => {
    setSelectedCountry(country);
    const langsInCountry = getLangsFromCountry({ countryId: country, countries });
    if (!langsInCountry.some(({ id }) => id === selectedLang)) {
      setSelectedLang(langsInCountry[0].id);
      AdditionalForm.setFieldsValue({ language: langsInCountry[0].id });
    }
  };

  return (
    <div className={styles.modalContentUpdate}>
      <div className={styles.contentPadded}>
        {IS_CAMPER ?
          <div className="text-center">
            <img src="/assets-new/newsletter-subscription-socks.gif" alt="Subscription OK!" height={70} />
          </div>
        : null}
        <p
          style={popupData?.title?.style}
          className={`mb-0 mt-2 text-center font-moderne-fat text-2xl nnormal:font-secondary md:text-3xl ${popupData?.title?.className || ''}`}
          dangerouslySetInnerHTML={{
            __html:
              popupData?.title.text ??
              t('generico', IS_CAMPER ? 'camper.newsletter.agradecimiento' : 'newsletter.agradecimiento', 'Thank you for your subscription!'),
          }}
        />
        <div className="flex flex-col gap-4">
          {popupData?.subtitle?.text ?
            <p
              style={popupData?.subtitle?.style}
              className={`max-w-[275px] text-center text-sm empty:hidden md:max-w-[438px] md:text-base ${styles.subtitleUpdate} ${popupData?.subtitle?.className || ''}`}
              dangerouslySetInnerHTML={{ __html: popupData?.subtitle?.text }}
            />
          : <>
              <p className={`md:max-w-[438px]'} max-w-[275px] text-center text-sm empty:hidden md:text-base ${styles.subtitleUpdate}`}>
                {t('generico', IS_CAMPER ? 'camper.newsletter.mas.info.title' : 'newsletter.mas.info.title', 'Tell Us About Yourself.')}
              </p>
              <p className={`max-w-[275px] text-center text-sm empty:hidden md:max-w-[438px] md:text-base ${styles.subtitleUpdate}`}>
                {t(
                  'generico',
                  IS_CAMPER ? 'camper.newsletter.mas.info.subtitle' : 'newsletter.mas.info.subtitle',
                  'It helps if we have a little bit of information about you.',
                )}
              </p>
            </>
          }
        </div>
        <Form
          form={AdditionalForm}
          layout="vertical"
          name="additionalForm"
          onFinish={onFinish}
          onFocus={handleUpdateFocus}
          onFieldsChange={handleFormError}
          scrollToFirstError
          className={styles.additionalForm}
          initialValues={initialValues}
        >
          <Form.Item label={t('generico', 'input.email', 'Email')} name="email" rules={validate('email')} required className={styles.emailInput}>
            <Input placeholder={t('generico', 'input.email.placeholder', 'Enter your email')} type="email" disabled formNoValidate />
          </Form.Item>
          <Form.Item
            name="telephone"
            label={t('generico', 'input.fecha.telefono')}
            className={styles.formPhone}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <div className={styles.phoneWrapper}>
              <PhoneInput
                info={{ phonePrefixes }}
                placeholder={t('generico', 'input.fecha.telefono', 'Phone')}
                rules={validate('telephone')}
                className={styles.newsletterPhone}
                withFloatLabel={false}
              />
            </div>
          </Form.Item>

          <FormInput
            name="date"
            label={t('generico', 'input.fecha.nacimiento', 'Date of birth')}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <div className={styles.datePicker}>
              <DatePicker
                locale={localeFormat}
                format={localeFormat.dateFormat}
                value={dateValue}
                onChange={(newDate) => {
                  AdditionalForm.setFields([
                    {
                      name: 'date',
                      value: newDate,
                      errors: [],
                    },
                  ]);
                  setDateValue(newDate);
                }}
                onBlur={() => {
                  // getFieldValue aquí nos devuelve undefined (o moment object)
                  // mientras que en myprofile nos devuelve una string
                  const realValue = AdditionalForm.getFieldValue('date');

                  if (realValue !== undefined) {
                    const formattedValue = moment(realValue, localeFormat.dateFormat);

                    if (formattedValue.isValid()) {
                      AdditionalForm.setFields([
                        {
                          name: 'date',
                          errors: [],
                        },
                      ]);
                      setDateValue(formattedValue);
                    } else {
                      if (realValue) {
                        AdditionalForm.setFields([
                          {
                            name: 'date',
                            errors: [t('newsletter', 'error.fecha.incorrecta.registro')],
                          },
                        ]);
                      }
                      setDateValue(undefined);
                    }
                  }
                }}
              />
            </div>
          </FormInput>

          <Form.Item name="gender" className={styles.genderSelector} valuePropName="" label={t('newsletter', 'genero')}>
            <Radio.Group>
              <Radio value="W">{t('generico', 'input.mujer', 'Women')}</Radio>
              <Radio value="M">{t('generico', 'input.hombre', 'Men')}</Radio>
            </Radio.Group>
          </Form.Item>

          <FormInput name="country" className={styles.selector} rules={validate('requiredCountry')} label={t('newsletter', 'pais', '**Country of residence')}>
            <Select
              ref={countryRef}
              showSearch
              value={selectedCountry}
              onSearch={handleSearchCountry}
              filterOption={false}
              onChange={(val) => {
                handleChangeCountry(val);
              }}
              placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
              key={selectedCountry}
              className={styles.modalSelect}
              suffixIcon={<ChevronSVG />}
            >
              {filteredCountries.map((country) => (
                <Select.Option key={country.id} value={country.id} className={styles.selectItem}>
                  <p>{country.name}</p>
                </Select.Option>
              ))}
            </Select>
          </FormInput>
          <FormInput name="language" className={styles.selector} rules={validate('requiredLanguage')} label={t('newsletter', 'idioma', '**Language')}>
            <Select
              ref={langRef}
              disabled={!selectedCountry}
              onChange={(val) => {
                setSelectedLang(val);
              }}
              placeholder={t('modal.idiomas', 'select.language', 'Select a language')}
              className={styles.modalSelect}
              suffixIcon={<ChevronSVG />}
              options={getLangsFromCountry({ countryId: selectedCountry, countries }).map((language) => ({
                value: language.id,
                label: language.name,
                className: `${styles.selectItem}`,
              }))}
            ></Select>
          </FormInput>

          <Form.Item className={styles.updateItem}>
            <Button
              type="default"
              htmlType="submit"
              className={`${styles.updateBtn} ${popupData?.submit?.className || ''}`}
              onClick={handleClickSubmit}
              style={popupData?.submit?.style}
            >
              <span dangerouslySetInnerHTML={{ __html: popupData?.submit?.text ?? t('generico', 'boton.actualizar', 'Update') }} />
            </Button>
          </Form.Item>
          <button
            onClick={onSkip}
            className={`${styles.cancel} ${popupData?.next?.className || ''}`}
            style={popupData?.next?.style}
            dangerouslySetInnerHTML={{ __html: popupData?.next?.text ?? t('generico', 'boton.cancelar', 'Cancel') }}
          />
        </Form>
      </div>
    </div>
  );
}

const sourcesWithCtas = ['header', 'footer', 'TiendaOnlinePopup', '1stscroll7d', '2nd90d', 'gridBanner'];

type ThanksStepProps = {
  popupInfo: any;
  source: string;
  onClose: () => void;
};

function ThanksStep({ popupInfo, source, onClose }: ThanksStepProps) {
  const {
    t,
    profileData: { emptyBagCollections },
  } = useI18n();
  const to = useLink();
  const { state, onCopy } = useCopyToClipboard(popupInfo?.voucher?.text);

  const handleClickCollectionButton = (collectionName) => {
    dataLayerHandleEvent({
      event: 'select_enrichment_end_promotion',
      event_category: 'acquisition',
      event_detail_1: collectionName,
    });
  };

  return (
    <div className="flex flex-col gap-4 p-10 text-center">
      {IS_CAMPER ?
        <img src="/assets-new/newsletter-subscription-socks.gif" alt="Subscription OK!" className="mx-auto" width={90} height={90} />
      : null}
      <p
        style={popupInfo?.title?.style}
        className={`mb-0 text-2xl nnormal:font-secondary ${popupInfo?.title?.className || ''}`}
        dangerouslySetInnerHTML={{ __html: popupInfo?.title?.text ?? t('generico', 'thank.you.newsletter') }}
      />
      <p
        style={popupInfo?.subtitle?.style}
        className={`mb-0 text-sm ${popupInfo?.subtitle?.className || ''}`}
        dangerouslySetInnerHTML={{ __html: popupInfo?.subtitle?.text ?? t('generico', 'see.you.soon.newsletter') }}
      />
      {popupInfo?.voucher?.text ?
        <div className="flex items-center justify-center">
          <div className="group flex h-10 items-center border border-black">
            <span className="m-0 flex flex-grow items-center justify-center px-4">{popupInfo?.voucher?.text}</span>
            <button
              className="text-dark-medium/80 hover:text-dark flex h-full w-10 items-center justify-center bg-black text-xs text-white transition-colors"
              onClick={onCopy}
            >
              {state === 'idle' ?
                <CopySVG className="size-6" />
              : <CheckSVG className="size-4" />}
            </button>
          </div>
        </div>
      : null}
      {IS_CAMPER && !Array.isArray(popupInfo?.ctas) ?
        <>
          <p className="mb-0 text-xs">{t('generico', 'thank.you.cta')}</p>
          <div className="my-2.5 grid grid-cols-2 gap-3">
            {emptyBagCollections?.map((item) => (
              <a
                key={`collectionButton-${item.title}`}
                href={isBlank(item.url) ? undefined : to(item.url)}
                onClick={() => handleClickCollectionButton(item.title)}
                className="flex h-10 min-w-[120px] items-center justify-center border-black bg-black px-2.5 text-center text-white transition-colors duration-75 hover:bg-white hover:text-black"
              >
                {item.title}
              </a>
            ))}
          </div>
        </>
      : null}
      {sourcesWithCtas.includes(source) ?
        Array.isArray(popupInfo?.ctas) && !!popupInfo.ctas.length ?
          <div className="my-2.5 grid grid-cols-2 gap-3">
            {popupInfo.ctas?.map((item) =>
              !isBlank(item.text) ?
                <a
                  key={`collectionButton-${item.title}`}
                  href={isBlank(item.href) ? undefined : to(item.href)}
                  style={item.style}
                  onClick={() => handleClickCollectionButton(item.text)}
                  className={`flex h-10 min-w-[120px] items-center justify-center border-[1px] border-black bg-black px-2.5 text-center text-white transition-colors duration-75 hover:bg-white hover:text-black ${item?.className || ''}`}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              : null,
            )}
          </div>
        : null
      : <div className="flex items-center justify-center">
          <Button type="default" htmlType="submit" onClick={onClose} className={`${styles.submitBtn} !mt-0`}>
            {t('generico', 'boton.cerrar', 'Close')}
          </Button>
        </div>
      }
    </div>
  );
}

export function NewsletterModal({
  visible = false,
  onClose = () => false,
  nlOrigin = '',
  pageName = '',
  source = null,
  sendUsrHash = false,
  camperlab = false,
  popupProps,
  cmsProps,
}) {
  const {
    profileData: { phonePrefixes },
    popupInfo,
    storeData,
    locale,
  } = useI18n();
  const { query } = useRouter();

  // Derived from global state
  // TODO: cambiar este check cuando se suba la nueva implementacion de useLocale
  const currentLanguage = getLang(locale);
  const market = getMarket(locale);
  const origin = `${nlOrigin}${camperlab ? '_camperlab' : ''}`;

  const [alreadySeen, setAlreadySeen] = useState(false);
  const [cookieBondValue, setCookieBondValue] = useState('');
  const [alreadySub, setAlreadySub] = useState(false);
  // const [ selectedPreffix, setSelectedPreffix ] = useState(null);
  const [step, setStep] = useState(STEP.SUBSCRIBE);
  const [loading, setLoading] = useState(true);
  const [selligentUrl, setSelligentUrl] = useState(null);
  const [selligentBase, setSelligentBase] = useState(null);
  const [loadingIframe, setLoadingIframe] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const [initialValues, setInitialValues] = useState({
    email: undefined,
    country: getMarket(locale),
    language: getLang(locale),
    telephone: undefined,
  });

  const seenErrors = [];

  const onCloseHandler = () => {
    if (localVisible === true) {
      setLocalVisible(false);
    }

    dataLayerHandleEvent({
      event: 'close_acquisition_popup',
      event_category: ACQUISITION_CATEGORY,
      event_detail_1: acquisitionDetail[step],
    });

    return onClose();
  };

  useEffect(() => {
    if (visible) {
      const cookieBonds = getCookie(COOKIES.BONDS);
      setAlreadySeen(true);
      setCookieBondValue(cookieBonds);
      dataLayerHandleEvent({
        event: 'impression_acquisition_form',
        event_category: ACQUISITION_CATEGORY,
      });

      dataLayerHandleEvent({
        event: 'acquisition_popup',
        info_type: 'impression',
        campaign_detail: popupInfo?.title?.toLowerCase() || '',
        page_type: window.gtmType || '',
      });
    }
    if (!visible && alreadySeen && selligentUrl !== null) {
      const currentCookieValue = getCookie(COOKIES.BONDS);
      if (currentCookieValue !== cookieBondValue) {
        window.location.reload();
      }
    }
    setLocalVisible(visible);
  }, [visible]);

  useEventListener('message', (event) => {
    const { data } = event;
    if (data === CLOSE_SELLIGENT_POPUP) {
      onClose();
    }
  });

  useEffect(() => {
    const getSelligentHandler = async () => {
      const selligentResult = await getIsSelligent(locale);
      const isMobileFromWindowWidth = window.innerWidth < 768;
      if (selligentResult) {
        const usrhashCookie = getCookie(COOKIES.SELLIGENT_USRHASH);
        let urlToSet = `${selligentResult}${camperlab ? '_camperlab' : ''}?lng=${currentLanguage}&country=${market.toLowerCase()}&isFromPopUp=true&device=${isMobileFromWindowWidth ? 'mobile' : 'desktop'}&source=${nlOrigin === 'promo' && pageName === 'PDP' ? 'pdp_message' : 'default'}`;

        if (usrhashCookie) {
          urlToSet = `${urlToSet}&usrhash=${usrhashCookie}`;
        }

        setSelligentBase(selligentResult);
        setSelligentUrl(urlToSet);
        setLoadingIframe(true);
        setLoading(false);
      } else if (selligentResult === false) {
        // no hay url selligent
        setLoading(false);
      }
    };

    getSelligentHandler();
  }, []);

  useEffect(() => {
    if (selligentBase !== null) {
      const selligentURLToSet = new URL(selligentUrl);
      const isMobileFromWindowWidth = window.innerWidth < 768;

      if (source !== null) {
        const sourceToSet = source === null ? 'default' : source;
        if (sourceToSet !== selligentURLToSet.searchParams.get('source')) {
          selligentURLToSet.searchParams.set('source', source);
        }
      }
      const userHash = getCookie(COOKIES.SELLIGENT_USRHASH);
      if (sendUsrHash && userHash && selligentURLToSet.searchParams.get('usrhash') === null) {
        selligentURLToSet.searchParams.set('usrhash', userHash);
      }
      if (isMobileFromWindowWidth && selligentURLToSet.searchParams.get('device') !== 'mobile') {
        selligentURLToSet.searchParams.set('device', 'mobile');
      }

      setSelligentUrl(selligentURLToSet.toString());
      setLoadingIframe(true);
    }
  }, [source, sendUsrHash]);

  const onFinish = async (values) => {
    const behaviour: TBehaviour = popupProps?.behaviour ?? 'always_on'; // private_promo | always_on | 'private_enrichment'
    const campaign = query.utm_campaign ?? popupProps?.campaign ?? null;
    const emailToUse = values.email;
    setInitialValues((prev) => ({
      ...prev,
      email: values.email,
      telephone: values.telephone ? values.telephone : prev.telephone,
    }));

    let phonePrefix = null;

    setLoading(true);

    if (values?.preffix) {
      const realPrefix = phonePrefixes.find((item) => item.id === values.preffix);

      if (realPrefix) {
        [phonePrefix] = realPrefix.name.split('(').pop().split(')');
      }
    }

    if (phonePrefix === null) {
      // no ha tocado el prefijo
      const defaultPrefix = phonePrefixes.find((item) => item.id === market);

      if (defaultPrefix) {
        [phonePrefix] = defaultPrefix.name.split('(').pop().split(')');
      }
    }

    const telephone = values.telephone ? `${phonePrefix} ${values.telephone}` : '';

    const payload: Record<string, unknown> = {
      email: emailToUse,
      telephone,
      phonePrefix,
      profile: {
        countryId: getMarket(locale),
        languageId: getLang(locale),
      },
      origin,
      campaign,
    };
    if (step === STEP.UPDATE) {
      payload.birthDate = payload.birthDate = values.date ? (values.date.format && values.date.format()) || values.date : '';
      if (values.gender) {
        payload.gender = values.gender.target.value;
      }
      payload.countryId = values.country;
      payload.languageId = values.language;
      payload.telephone = values.telephone || '';
      payload.origin = origin;
    }
    try {
      const result = step === STEP.SUBSCRIBE ? await subscribeNewsletter(payload) : await updateNewsletter(payload);
      if (step === STEP.SUBSCRIBE) {
        /**
         * Aqui hay que manejar a que step movemos al usuario:
         * - "always_on": Si estas registrado no avanzas; si no lo estas -> Step 2 (data enrichment)
         * - "private_promo": Si estas registrado o no vas al step 3
         * - "private_enrichment"(nuevo): Si estas registrado -> Step 3; si no estas registrado -> Step 2 (data enrichment)
         * */
        if (behaviour === 'always_on') {
          // always_on y ya está suscrito
          if (result.contactAlreadySubscribed) {
            setAlreadySub(true);
            dataLayerHandleEvent({
              event: `error_${acquisitionDetail[step]}`,
              event_category: ACQUISITION_CATEGORY,
              event_detail_1: 'e-mail is already subscribed',
              event_detail_2: 'email',
            });
            // "always_on" y no está suscrito
          } else {
            setAlreadySub(false);
            setStep(STEP.UPDATE);

            dataLayerHandleEvent({
              event: 'generate_lead',
              info_category: 'lead',
            });
            dataLayerHandleEvent({
              event: 'success_acquisition_form',
              event_category: ACQUISITION_CATEGORY,
            });
            dataLayerHandleEvent({
              event: 'eventGA',
              eventAct: 'impression',
              eventCat: 'AcquisitionPopup',
              eventLbl: origin,
            });
            dataLayerHandleEvent({
              event: 'acquisition_popup',
              info_type: 'subscription',
              campaign_detail: popupInfo?.title?.toLowerCase() || '',
              page_type: window.gtmType || '',
            });

            dataLayerHandleEvent({
              event: 'eventGA',
              eventCat: 'AcquisitionPopup',
              eventAct: 'subscription',
              eventLbl: window.dataLayer?.find((key) => key.eventCat === 'AcquisitionPopup' && key.eventAct === 'impression')?.eventLbl,
            });
            dataLayerHandleEvent({
              event: 'eventGA',
              eventCat: 'NewsletterSubscription',
              eventLbl: origin,
              eventAct: getMarket(locale),
            });
            dataLayerHandleEvent({
              event: 'mailShadowed',
              mailSHA256: SHA256(values?.email?.toLowerCase()).toString(),
              mailVanilla: values?.email,
            });
          }
        }
        if (behaviour === 'private_promo') {
          setStep(STEP.THANKS);
          const eventEmail = result.email?.toLowerCase() ?? values?.email?.toLowerCase();
          if (result.contactAlreadySubscribed === false) {
            dataLayerHandleEvent({
              event: 'generate_lead',
              info_category: 'lead',
            });
          }
          dataLayerHandleEvent({
            event: 'success_acquisition_form',
            event_category: ACQUISITION_CATEGORY,
          });
          dataLayerHandleEvent({
            event: 'mailShadowed',
            mailSHA256: SHA256(eventEmail).toString(),
            mailVanilla: eventEmail,
          });
        }
        if (behaviour === 'private_enrichment') {
          const eventEmail = result.email?.toLowerCase() ?? values?.email?.toLowerCase();
          // private_enrichment y ya está suscrito
          if (result.contactAlreadySubscribed) {
            setStep(STEP.THANKS);
            dataLayerHandleEvent({
              event: 'success_acquisition_form',
              event_category: ACQUISITION_CATEGORY,
            });
            dataLayerHandleEvent({
              event: 'mailShadowed',
              mailSHA256: SHA256(eventEmail).toString(),
              mailVanilla: eventEmail,
            });
            // private_enrichment y no está suscrito
          } else {
            setStep(STEP.UPDATE);

            dataLayerHandleEvent({
              event: 'generate_lead',
              info_category: 'lead',
            });
            dataLayerHandleEvent({
              event: 'success_acquisition_form',
              event_category: ACQUISITION_CATEGORY,
            });
            dataLayerHandleEvent({
              event: 'eventGA',
              eventAct: 'impression',
              eventCat: 'AcquisitionPopup',
              eventLbl: origin,
            });

            dataLayerHandleEvent({
              event: 'acquisition_popup',
              info_type: 'subscription',
              campaign_detail: popupInfo?.title?.toLowerCase() || '',
              page_type: window.gtmType || '',
            });

            dataLayerHandleEvent({
              event: 'eventGA',
              eventCat: 'AcquisitionPopup',
              eventAct: 'subscription',
              eventLbl: window.dataLayer.find((key) => key.eventCat === 'AcquisitionPopup' && key.eventAct === 'impression')?.eventLbl,
            });
            dataLayerHandleEvent({
              event: 'eventGA',
              eventCat: 'NewsletterSubscription',
              eventLbl: origin,
              eventAct: getMarket(locale),
            });
            dataLayerHandleEvent({
              event: 'mailShadowed',
              mailSHA256: SHA256(values?.email?.toLowerCase()).toString(),
              mailVanilla: values?.email,
            });
          }
        }
      }
      if (step === STEP.UPDATE) {
        dataLayerHandleEvent({
          event: 'success_enrichment_form',
          event_category: ACQUISITION_CATEGORY,
        });
        setStep(STEP.THANKS);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  let modalClass = step !== STEP.THANKS ? styles.modalWrapper : `${styles.modalWrapper} ${styles.modalWrapperThanks}`;

  if (selligentUrl !== null) {
    modalClass = `${modalClass} ${styles.selligentModal}`;
  }

  const handleLoadIframe = () => {
    setLoadingIframe(false);
  };

  const handleFormError = (fieldsData) => {
    if (fieldsData) {
      fieldsData.forEach((fieldItem) => {
        const { errors, name } = fieldItem;

        if (errors.length > 0) {
          if (!seenErrors.includes(name[0])) {
            if (fieldsData.length > 1) {
              // si tiene length 1 no ha pulsado el botón
              dataLayerHandleEvent({
                event: `error_${acquisitionDetail[step]}`,
                event_category: ACQUISITION_CATEGORY,
                event_detail_1: 'validation error',
                event_detail_2: name[0],
              });
            }

            seenErrors.push(name[0]);
          }
        } else if (seenErrors.includes(name[0])) {
          // no longer has an error
          const indexOfError = seenErrors.indexOf(name[0]);
          seenErrors.splice(indexOfError, 1);
        }
      });
    }
  };

  const handleClickSubmit = () => {
    seenErrors.splice(0, seenErrors.length);
    dataLayerHandleEvent({
      event: `try_${step === STEP.UPDATE ? 'enrichment' : 'acquisition'}_form`,
      event_category: ACQUISITION_CATEGORY,
    });
  };

  return (
    // @ts-expect-error antd error
    <Modal
      title=""
      visible={storeData ? false : localVisible}
      footer={null}
      onCancel={onCloseHandler}
      wrapClassName={`${modalClass}`}
      closable={false}
      destroyOnClose={false}
      zIndex={1000}
      bodyStyle={popupProps?.style}
    >
      {/* @ts-expect-error antd error */}
      <Spin spinning={loading}>
        {
          selligentUrl === null ?
            <>
              <span onClick={onCloseHandler} className={styles.closeSvg}>
                <CloseModalSVG />
              </span>

              {step === STEP.SUBSCRIBE ?
                <SubscribeStep
                  alreadySub={alreadySub}
                  onFinish={onFinish}
                  handleFormError={handleFormError}
                  handleClickSubmit={handleClickSubmit}
                  popupData={popupProps?.emailStep}
                  cmsProps={cmsProps}
                />
              : null}
              {step === STEP.UPDATE ?
                <UpdateStep
                  onFinish={onFinish}
                  handleFormError={handleFormError}
                  initialValues={initialValues}
                  handleClickSubmit={handleClickSubmit}
                  onSkip={() => setStep(STEP.THANKS)}
                  popupData={popupProps?.extraStep}
                />
              : null}

              {step === STEP.THANKS ?
                <ThanksStep popupInfo={popupProps?.thanksStep} source={source} onClose={onClose} />
              : null}
            </>
            //@ts-expect-error antd error
          : <Spin spinning={loadingIframe}>
              <>
                <iframe id="selligent" onLoad={handleLoadIframe} className={styles.selligentIframe} src={`${selligentUrl}`}></iframe>
                <span className={styles.closeButton} onClick={onCloseHandler}>
                  <CloseMdSVG closeBgColor={popupInfo?.closeBgColor} />
                </span>
              </>
            </Spin>

        }
      </Spin>
    </Modal>
  );
}

// NewsletterModal.propTypes = {
//   visible: bool,
//   onClose: func,
//   countries: array,
//   nlOrigin: string,
//   pageName: string,
//   source: string,
//   sendUsrHash: bool,
//   cmsProps: shape({
//     newsletterPopup: shape({
//       newsletterPopupClaim: string,
//       newsletterPopupSubclaim: string,
//     }),
//     newsletterContent: shape({
//       description: string,
//       placeholder: string,
//       title: string,
//     }),
//     newsletterPopupImages: shape({
//       newsletterImages: arrayOf(shape({
//         fileName: string,
//         url: string,
//         tags: arrayOf(string),
//         metadata: shape({
//           pixelWidth: number,
//           pixelHeight: number,
//           imageQuality: number,
//           description: string,
//         }),
//       })),
//       newsletterIcon: oneOfType([ shape({
//         fileName: string,
//         url: string,
//         tags: arrayOf(string),
//       }), string ]),
//     }),
//   }),
//   camperlab: bool,
// };
